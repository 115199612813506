import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "admin-flex" }

import AdminBar from '@/components/Admin/AdminBar.vue'
import LayoutPage from '@/shared/Layout/LayoutPage.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'AdminBlock',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LayoutPage, null, {
      default: _withCtx(() => [
        _createVNode(AdminBar, { class: "admin-bar" }),
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ]))
}
}

})