import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './lang/i18n'
import ElementPlus from 'element-plus'
import { createPinia } from 'pinia'
import { money } from './helpers/prototype'
const pinia = createPinia()

const app = createApp(App)
app.config.globalProperties.$money = money

app.use(ElementPlus)

app.use(i18n).use(pinia).use(router).mount('#app')
