import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import { InfoFilled } from '@element-plus/icons-vue'
import { ElTooltip, ElIcon } from 'element-plus'


export default /*@__PURE__*/_defineComponent({
  __name: 'infoTooltip',
  props: {
    value: {},
    place: {},
    ip: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_ctx.value)
    ? (_openBlock(), _createBlock(_unref(ElTooltip), {
        key: 0,
        effect: "dark",
        placement: _ctx.place || 'right'
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", {
            style: {"max-width":"350px"},
            innerHTML: _ctx.value
          }, null, 8, _hoisted_1)
        ]),
        default: _withCtx(() => [
          (_ctx.ip !== '127.0.0.1')
            ? (_openBlock(), _createBlock(_unref(ElIcon), {
                key: 0,
                class: "icon-position",
                size: 14,
                color: "#D7D7D7"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(InfoFilled))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["placement"]))
    : _createCommentVNode("", true)
}
}

})