<template>
  <router-view/>
</template>

<script>
// import { setCookie } from '@/helpers/cookie'
// сохраняет последний url на котором был пользователь, кроме authorize - для токена
// if (location.pathname !== '/authorize') setCookie('patch', location.pathname, { path: '/' })
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "assets/styles/app.scss";
</style>
