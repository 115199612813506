import axios from 'axios'
import { getCookie, deleteCookie } from '@/helpers/cookie'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 10000,
  headers: {
    'content-type': 'application/json'
    // 'content-type': 'multipart/form-data',
  }
})

http.interceptors.request.use(async (config: any) => {
  const token = getCookie('token') || ''
  if (typeof token === 'undefined' || token === 'undefined') {
    // dispatch('login')
    window.location.replace('/')
    return false
  }
  if (token.length > 0) {
    const platform = getCookie('platform') || ''
    config.headers['X-API-TOKEN'] = token
    config.headers['X-PROJECT'] = platform
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

http.interceptors.response.use(
  config => config,
  error => {
    if (error.response.status === 401) {
      delete error.response.config.headers['X-API-TOKEN']
      delete error.response.config.headers['X-PROJECT']
      deleteCookie('token')
      window.location.replace('/')
    }
    return Promise.reject(error)
  },
)

export default http
