/**
 * @description https://kyc-api-staging.compliancecircle.com/api/docs
 */
export default {
  // azure: '/management/auth/azure', // azure
  auth: '/api/auth/redirect_url', // получить код от ажура
  authLogin: '/api/auth/login', // reserve
  login: (code:string) => `/api/auth/login?code=${code}`, // получить токен
  logout: '/api/auth/logout', // logout azure
  userInfo: '/api/auth/me',
  loginInfo: '/login/info',

  /**
   * @description Customer List
   */
  clients: (query:string) => `/api/customer?${query}`,
  client: (id:number) => `/api/customer/${id}`,
  /**
   * @description Customer List
   * @param { number } customerId
   * @param { string } include
   */
  clientAddress: (customerId:number, include: string) => `/api/customer/${customerId}/address${include}`,
  /**
   * @description documents List
   * @param { number } customerId
   */
  clientDocuments: (customerId:number) => `/api/customer/${customerId}/document`,
  clientDownloadDocumentsArchive: (customerId:number) => `/api/customer/${customerId}/kycOverview`,
  clientPhone: (customerId:number) => `/api/customer/${customerId}/phone`,
  clientEmail: (customerId:number) => `/api/customer/${customerId}/email`,
  clientDirector: (customerId:number) => `/api/customer/${customerId}/director`,
  clientRepresentative: (customerId:number) => `/api/customer/${customerId}/representative`,
  clientApprove: (customerId:number) => `/api/customer/${customerId}/assign`,
  /**
   * @description managers assign customer on manager
   * @method POST
   * @param {number} customerId
   * @body {number} userId
   */
  clientAssign: (customerId:number) => `/api/customer/${customerId}/assign`,
  /**
   * @description close customer
   * @method POST
   * @param {number} customerId
   * @body {string} reason // required Close reason note
   * @body {string} customerNote // Customer note
   * @body {boolen} force // Close an account even if the balance is not empty
   */
  clientClose: (customerId:number) => `/api/customer/${customerId}/close`,
  /**
   * @description List of customer verification requests
   * @param customerId
   */
  clientVerify: (customerId:number) => `/api/customer/${customerId}/verificationRequest`,
  /**
   * @description Customer Limit Info
   * @param customerId
   */
  clientLimit: (customerId:number) => `/api/customer/${customerId}/limit`,
  clientUnlimit: (customerId:number) => `/api/customer/${customerId}/unlimit`,
  /**
   * @description Customer Risk Assesment List
   * @param customerId
   * GET
   */
  clientRisk: (customerId:number) => `/api/customer/${customerId}/risk`,
  /**
   * @description Customer Risk Assesment Update
   * @param id Customer Risk Assessment ID
   * PUT
   */

  clientRiskUpdate: (id:number) => `/api/risk/${id}`,

  /**
   * @description Kyc Request Get
   * @param customerId
   * CRUD
   */
  kycRequest: (customerId:number) => `/api/customer/${customerId}/kycRequest`,

  /**
   * @description Verification Request List
   * @param customerId
   * GET
   */
  verificationRequestList: (customerId:number) => `/api/customer/${customerId}/verificationRequest`,
  /**
   * @description Verification Request Edd Complete
   * @param id Verification request ID
   * POST
   */
  verificationRequestComplete: (id:number) => `/api/verificationRequest/${id}/completeEdd`,
  /**
   * @description Verification Request Update
   * @param id Verification request ID
   * PUT
   */
  verificationRequestUpdate: (id:number) => `/api/verificationRequest/${id}`,
  /**
   * @description documents List
   */
  documents: (query:string) => `/api/document?${query}`,
  documentUpload: '/api/document',
  document: (documentId:number, include: string) => `/api/document/${documentId}${include}`,
  documentLoad: (documentId:number) => `/api/document/${documentId}/download`,
  documentAddress: (documentId:number) => `/api/document/${documentId}/address`,
  /**
   * @description document Last
   * @method GET
   * @param {string} query
   * @query {string} status
   * @query {string} type
   * @query {string} country
   * @query {string} state
   * @query {number} size
   */
  documentLast: (query:string) => '/api/document/last?' + query,
  /**
   * ??
   * @description assign document
   * @method POST
   * @param {number} documentId
   * @body {number} userId
   */
  documentAssign: (documentId:number) => `/api/document/${documentId}/assign`,
  /**
   * @description edit document status
   * @method POST
   * @param {number} documentId
   * @param {string} type (verify, reject)
   * @ищвн {string} rejectReasonId
   */
  documentStatus: (documentId:number, type:string) => `/api/document/${documentId}/${type}`,
  documentReject: (confirmationType:string) => `/api/dictionary/documentRejectReason?confirmationType=${confirmationType}`,

  // dict
  /**
   * @description Document Category List
   */
  dictCategories: '/api/dictionary/documentCategories',
  dictDocTypes: '/api/dictionary/documentType',
  /**
   * @description Fee Group List
   */
  dictFee: '/api/dictionary/feeGroup',
  /**
   * @description managers List for assign
   * @param {string} query
   */
  managers: (query:string) => `/api/user${query}`,
  manager: (userId:number) => `/api/user/${userId}`,
  /**
   * @description managers List for assign
   * GET
   * @query {string} customerId
   * @query {number} depth
   * @query {string} dateFrom
   * @query {string} direction
   * @param {string} query
   */
  queryGraph: (query:string) => `/api/queryGraph?${query}`,
  queryGraphTransfer: (query:string) => `/api/queryGraph/transfer?${query}`,
  // TICKET
  /**
   * @method GET
   * @description получение тикетов из Зохо по customerId
   * @param { Number } customerId
   */
  tickets: (customerId:number) => `/api/zoho/ticket/search?customerId=${customerId}`,
  /**
   * @method GET
   * @description Посмотреть конкретный шаблон по ticketId
   * @param ticketId = number
   */
  ticket: (ticketId:number) => `/api/zoho/ticket/${ticketId}/threads`,
  /**
   * @method GET
   * @description дополнительное поле content с полной htmlкой
   * @param { number } ticketId
   * @param { number } threadId
   * @param { String } platform
   */
  ticketContent: (ticketId:number, platform:string, threadId:number) => `/management/helpdesk/thread/${platform}/${ticketId}/${threadId}`,
  /**
   * @method POST
   * @description add New Ticket Tickets
   * @body {

   * }
   */
  addTicket: '/api/zoho/ticket',
  /**
   * @method GET
   * @description Шаблоны для (subject, description):
   * @param { String } platform
   */
  TempHelpdesk: '/api/zoho/template',

  /**
   * @method GET
   * @description получение списка команд(групп людей) в зохо
   */
  TeamHelpdesk: '/api/zoho/team',
  /**
   * @method GET
   * @description Email адреса кастомера для email select в форме создания тикета:
   * @param { String } customerId
   */
  contactHelpdesk: (customerId:number) => `/api/zoho/contact/search?customerId=${customerId}`,
  /**
   * @method GET
   * @description from email address list
   */
  replyAddress: '/api/zoho/replyAddress',
  /**
   * @method POST
   * @description send Mess in ticketId:
   * @param { number } ticketId
   * @body {
   *   from: String,
   *   to: String
   *   content: String
   * }
   */
  sendReply: (ticketId:number) => `/api/zoho/ticket/${ticketId}/sendReply`,

  /**
   * @method GET
   * @description Blacklists
   * @param { string } query
   * @query {
   *   keyword: String,
   *   type: String
   *   status: String
   *   dateFrom: String
   *   dateFrom: String
   *   dateTo: String
   *   page: number
   *   size: number
   * }
   */
  blacklist: (query:string) => `/api/denylist${query}`,
  /**
   * @method POST
   * @description Blacklist Create
   * @body {
   *   type: String
   *   value: String
   *   note: String
   * }
   */
  blacklistAdd: '/api/denylist',
  /**
   * @method GET
   * @description Blacklist Info
   * @param { number } blacklistId
   */
  blacklistInfo: (blacklistId:number) => `/api/denylist/${blacklistId}`,
  /**
   * @method PUT
   * @description Blacklist Update
   * @param { number } blacklistId
   * @body {
   *   status: string
   * }
   */
  blacklistEdit: (blacklistId:number) => `/api/denylist/${blacklistId}`,
  /**
   * @method GET
   * @DEV
   * @description   со сторонего АПИ
   * @param { Number } customerId
   */
  customerSanctions_API: (customerId: number) => `/customers/${customerId}/sanctions`,
  customerInfo_API: (customerId: number) => `/customers/${customerId}/`,
  getLinkZohoForm: (customerId: number) => `/api/customer/${customerId}/zoho_forms`,
}
