export const country = [
  {
    id: 'AD',
    emoji: '🇦🇩',
    unicode: 'U+1F1E6 U+1F1E9',
    value: 'Andorra',
    title: 'flag for Andorra',
    dialCode: '+376'
  },
  {
    id: 'AE',
    emoji: '🇦🇪',
    unicode: 'U+1F1E6 U+1F1EA',
    value: 'United Arab Emirates',
    title: 'flag for United Arab Emirates',
    dialCode: '+971'
  },
  {
    id: 'AF',
    emoji: '🇦🇫',
    unicode: 'U+1F1E6 U+1F1EB',
    value: 'Afghanistan',
    title: 'flag for Afghanistan',
    dialCode: '+93'
  },
  {
    id: 'AG',
    emoji: '🇦🇬',
    unicode: 'U+1F1E6 U+1F1EC',
    value: 'Antigua and Barbuda',
    title: 'flag for Antigua and Barbuda',
    dialCode: '+1268'
  },
  {
    id: 'AI',
    emoji: '🇦🇮',
    unicode: 'U+1F1E6 U+1F1EE',
    value: 'Anguilla',
    title: 'flag for Anguilla',
    dialCode: '+1 264'
  },
  {
    id: 'AL',
    emoji: '🇦🇱',
    unicode: 'U+1F1E6 U+1F1F1',
    value: 'Albania',
    title: 'flag for Albania',
    dialCode: '+355'
  },
  {
    id: 'AM',
    emoji: '🇦🇲',
    unicode: 'U+1F1E6 U+1F1F2',
    value: 'Armenia',
    title: 'flag for Armenia',
    dialCode: '+374'
  },
  {
    id: 'AO',
    emoji: '🇦🇴',
    unicode: 'U+1F1E6 U+1F1F4',
    value: 'Angola',
    title: 'flag for Angola',
    dialCode: '+244'
  },
  {
    id: 'AQ',
    emoji: '🇦🇶',
    unicode: 'U+1F1E6 U+1F1F6',
    value: 'Antarctica',
    title: 'flag for Antarctica',
    dialCode: null
  },
  {
    id: 'AR',
    emoji: '🇦🇷',
    unicode: 'U+1F1E6 U+1F1F7',
    value: 'Argentina',
    title: 'flag for Argentina',
    dialCode: '+54'
  },
  {
    id: 'AS',
    emoji: '🇦🇸',
    unicode: 'U+1F1E6 U+1F1F8',
    value: 'American Samoa',
    title: 'flag for American Samoa',
    dialCode: '+1 684'
  },
  {
    id: 'AT',
    emoji: '🇦🇹',
    unicode: 'U+1F1E6 U+1F1F9',
    value: 'Austria',
    title: 'flag for Austria',
    dialCode: '+43'
  },
  {
    id: 'AU',
    emoji: '🇦🇺',
    unicode: 'U+1F1E6 U+1F1FA',
    value: 'Australia',
    title: 'flag for Australia',
    dialCode: '+61'
  },
  {
    id: 'AW',
    emoji: '🇦🇼',
    unicode: 'U+1F1E6 U+1F1FC',
    value: 'Aruba',
    title: 'flag for Aruba',
    dialCode: '+297'
  },
  {
    id: 'AX',
    emoji: '🇦🇽',
    unicode: 'U+1F1E6 U+1F1FD',
    value: 'Åland Islands',
    title: 'flag for Åland Islands',
    dialCode: ''
  },
  {
    id: 'AZ',
    emoji: '🇦🇿',
    unicode: 'U+1F1E6 U+1F1FF',
    value: 'Azerbaijan',
    title: 'flag for Azerbaijan',
    dialCode: '+994'
  },
  {
    id: 'BA',
    emoji: '🇧🇦',
    unicode: 'U+1F1E7 U+1F1E6',
    value: 'Bosnia and Herzegovina',
    title: 'flag for Bosnia and Herzegovina',
    dialCode: '+387'
  },
  {
    id: 'BB',
    emoji: '🇧🇧',
    unicode: 'U+1F1E7 U+1F1E7',
    value: 'Barbados',
    title: 'flag for Barbados',
    dialCode: '+1 246'
  },
  {
    id: 'BD',
    emoji: '🇧🇩',
    unicode: 'U+1F1E7 U+1F1E9',
    value: 'Bangladesh',
    title: 'flag for Bangladesh',
    dialCode: '+880'
  },
  {
    id: 'BE',
    emoji: '🇧🇪',
    unicode: 'U+1F1E7 U+1F1EA',
    value: 'Belgium',
    title: 'flag for Belgium',
    dialCode: '+32'
  },
  {
    id: 'BF',
    emoji: '🇧🇫',
    unicode: 'U+1F1E7 U+1F1EB',
    value: 'Burkina Faso',
    title: 'flag for Burkina Faso',
    dialCode: '+226'
  },
  {
    id: 'BG',
    emoji: '🇧🇬',
    unicode: 'U+1F1E7 U+1F1EC',
    value: 'Bulgaria',
    title: 'flag for Bulgaria',
    dialCode: '+359'
  },
  {
    id: 'BH',
    emoji: '🇧🇭',
    unicode: 'U+1F1E7 U+1F1ED',
    value: 'Bahrain',
    title: 'flag for Bahrain',
    dialCode: '+973'
  },
  {
    id: 'BI',
    emoji: '🇧🇮',
    unicode: 'U+1F1E7 U+1F1EE',
    value: 'Burundi',
    title: 'flag for Burundi',
    dialCode: '+257'
  },
  {
    id: 'BJ',
    emoji: '🇧🇯',
    unicode: 'U+1F1E7 U+1F1EF',
    value: 'Benin',
    title: 'flag for Benin',
    dialCode: '+229'
  },
  {
    id: 'BL',
    emoji: '🇧🇱',
    unicode: 'U+1F1E7 U+1F1F1',
    value: 'Saint Barthélemy',
    title: 'flag for Saint Barthélemy',
    dialCode: '+590'
  },
  {
    id: 'BM',
    emoji: '🇧🇲',
    unicode: 'U+1F1E7 U+1F1F2',
    value: 'Bermuda',
    title: 'flag for Bermuda',
    dialCode: '+1 441'
  },
  {
    id: 'BN',
    emoji: '🇧🇳',
    unicode: 'U+1F1E7 U+1F1F3',
    value: 'Brunei Darussalam',
    title: 'flag for Brunei Darussalam',
    dialCode: '+673'
  },
  {
    id: 'BO',
    emoji: '🇧🇴',
    unicode: 'U+1F1E7 U+1F1F4',
    value: 'Bolivia',
    title: 'flag for Bolivia',
    dialCode: '+591'
  },
  {
    id: 'BQ',
    emoji: '🇧🇶',
    unicode: 'U+1F1E7 U+1F1F6',
    value: 'Bonaire, Sint Eustatius and Saba',
    title: 'flag for Bonaire, Sint Eustatius and Saba'
  },
  {
    id: 'BR',
    emoji: '🇧🇷',
    unicode: 'U+1F1E7 U+1F1F7',
    value: 'Brazil',
    title: 'flag for Brazil',
    dialCode: '+55'
  },
  {
    id: 'BS',
    emoji: '🇧🇸',
    unicode: 'U+1F1E7 U+1F1F8',
    value: 'Bahamas',
    title: 'flag for Bahamas',
    dialCode: '+1 242'
  },
  {
    id: 'BT',
    emoji: '🇧🇹',
    unicode: 'U+1F1E7 U+1F1F9',
    value: 'Bhutan',
    title: 'flag for Bhutan',
    dialCode: '+975'
  },
  {
    id: 'BV',
    emoji: '🇧🇻',
    unicode: 'U+1F1E7 U+1F1FB',
    value: 'Bouvet Island',
    title: 'flag for Bouvet Island'
  },
  {
    id: 'BW',
    emoji: '🇧🇼',
    unicode: 'U+1F1E7 U+1F1FC',
    value: 'Botswana',
    title: 'flag for Botswana',
    dialCode: '+267'
  },
  {
    id: 'BY',
    emoji: '🇧🇾',
    unicode: 'U+1F1E7 U+1F1FE',
    value: 'Belarus',
    title: 'flag for Belarus',
    dialCode: '+375'
  },
  {
    id: 'BZ',
    emoji: '🇧🇿',
    unicode: 'U+1F1E7 U+1F1FF',
    value: 'Belize',
    title: 'flag for Belize',
    dialCode: '+501'
  },
  {
    id: 'CA',
    emoji: '🇨🇦',
    unicode: 'U+1F1E8 U+1F1E6',
    value: 'Canada',
    title: 'flag for Canada',
    dialCode: '+1'
  },
  {
    id: 'CC',
    emoji: '🇨🇨',
    unicode: 'U+1F1E8 U+1F1E8',
    value: 'Cocos (Keeling) Islands',
    title: 'flag for Cocos (Keeling) Islands',
    dialCode: '+61'
  },
  {
    id: 'CD',
    emoji: '🇨🇩',
    unicode: 'U+1F1E8 U+1F1E9',
    value: 'Congo',
    title: 'flag for Congo',
    dialCode: '+243'
  },
  {
    id: 'CF',
    emoji: '🇨🇫',
    unicode: 'U+1F1E8 U+1F1EB',
    value: 'Central African Republic',
    title: 'flag for Central African Republic',
    dialCode: '+236'
  },
  {
    id: 'CG',
    emoji: '🇨🇬',
    unicode: 'U+1F1E8 U+1F1EC',
    value: 'Congo',
    title: 'flag for Congo',
    dialCode: '+242'
  },
  {
    id: 'CH',
    emoji: '🇨🇭',
    unicode: 'U+1F1E8 U+1F1ED',
    value: 'Switzerland',
    title: 'flag for Switzerland',
    dialCode: '+41'
  },
  {
    id: 'CI',
    emoji: '🇨🇮',
    unicode: 'U+1F1E8 U+1F1EE',
    value: 'Côte D\'Ivoire',
    title: 'flag for Côte D\'Ivoire',
    dialCode: '+225'
  },
  {
    id: 'CK',
    emoji: '🇨🇰',
    unicode: 'U+1F1E8 U+1F1F0',
    value: 'Cook Islands',
    title: 'flag for Cook Islands',
    dialCode: '+682'
  },
  {
    id: 'CL',
    emoji: '🇨🇱',
    unicode: 'U+1F1E8 U+1F1F1',
    value: 'Chile',
    title: 'flag for Chile',
    dialCode: '+56'
  },
  {
    id: 'CM',
    emoji: '🇨🇲',
    unicode: 'U+1F1E8 U+1F1F2',
    value: 'Cameroon',
    title: 'flag for Cameroon',
    dialCode: '+237'
  },
  {
    id: 'CN',
    emoji: '🇨🇳',
    unicode: 'U+1F1E8 U+1F1F3',
    value: 'China',
    title: 'flag for China',
    dialCode: '+86'
  },
  {
    id: 'CO',
    emoji: '🇨🇴',
    unicode: 'U+1F1E8 U+1F1F4',
    value: 'Colombia',
    title: 'flag for Colombia',
    dialCode: '+57'
  },
  {
    id: 'CR',
    emoji: '🇨🇷',
    unicode: 'U+1F1E8 U+1F1F7',
    value: 'Costa Rica',
    title: 'flag for Costa Rica',
    dialCode: '+506'
  },
  {
    id: 'CU',
    emoji: '🇨🇺',
    unicode: 'U+1F1E8 U+1F1FA',
    value: 'Cuba',
    title: 'flag for Cuba',
    dialCode: '+53'
  },
  {
    id: 'CV',
    emoji: '🇨🇻',
    unicode: 'U+1F1E8 U+1F1FB',
    value: 'Cape Verde',
    title: 'flag for Cape Verde',
    dialCode: '+238'
  },
  {
    id: 'CW',
    emoji: '🇨🇼',
    unicode: 'U+1F1E8 U+1F1FC',
    value: 'Curaçao',
    title: 'flag for Curaçao'
  },
  {
    id: 'CX',
    emoji: '🇨🇽',
    unicode: 'U+1F1E8 U+1F1FD',
    value: 'Christmas Island',
    title: 'flag for Christmas Island',
    dialCode: '+61'
  },
  {
    id: 'CY',
    emoji: '🇨🇾',
    unicode: 'U+1F1E8 U+1F1FE',
    value: 'Cyprus',
    title: 'flag for Cyprus',
    dialCode: '+537'
  },
  {
    id: 'CZ',
    emoji: '🇨🇿',
    unicode: 'U+1F1E8 U+1F1FF',
    value: 'Czech Republic',
    title: 'flag for Czech Republic',
    dialCode: '+420'
  },
  {
    id: 'DE',
    emoji: '🇩🇪',
    unicode: 'U+1F1E9 U+1F1EA',
    value: 'Germany',
    title: 'flag for Germany',
    dialCode: '+49'
  },
  {
    id: 'DJ',
    emoji: '🇩🇯',
    unicode: 'U+1F1E9 U+1F1EF',
    value: 'Djibouti',
    title: 'flag for Djibouti',
    dialCode: '+253'
  },
  {
    id: 'DK',
    emoji: '🇩🇰',
    unicode: 'U+1F1E9 U+1F1F0',
    value: 'Denmark',
    title: 'flag for Denmark',
    dialCode: '+45'
  },
  {
    id: 'DM',
    emoji: '🇩🇲',
    unicode: 'U+1F1E9 U+1F1F2',
    value: 'Dominica',
    title: 'flag for Dominica',
    dialCode: '+1 767'
  },
  {
    id: 'DO',
    emoji: '🇩🇴',
    unicode: 'U+1F1E9 U+1F1F4',
    value: 'Dominican Republic',
    title: 'flag for Dominican Republic',
    dialCode: '+1 849'
  },
  {
    id: 'DZ',
    emoji: '🇩🇿',
    unicode: 'U+1F1E9 U+1F1FF',
    value: 'Algeria',
    title: 'flag for Algeria',
    dialCode: '+213'
  },
  {
    id: 'EC',
    emoji: '🇪🇨',
    unicode: 'U+1F1EA U+1F1E8',
    value: 'Ecuador',
    title: 'flag for Ecuador',
    dialCode: '+593'
  },
  {
    id: 'EE',
    emoji: '🇪🇪',
    unicode: 'U+1F1EA U+1F1EA',
    value: 'Estonia',
    title: 'flag for Estonia',
    dialCode: '+372'
  },
  {
    id: 'EG',
    emoji: '🇪🇬',
    unicode: 'U+1F1EA U+1F1EC',
    value: 'Egypt',
    title: 'flag for Egypt',
    dialCode: '+20'
  },
  {
    id: 'EH',
    emoji: '🇪🇭',
    unicode: 'U+1F1EA U+1F1ED',
    value: 'Western Sahara',
    title: 'flag for Western Sahara'
  },
  {
    id: 'ER',
    emoji: '🇪🇷',
    unicode: 'U+1F1EA U+1F1F7',
    value: 'Eritrea',
    title: 'flag for Eritrea',
    dialCode: '+291'
  },
  {
    id: 'ES',
    emoji: '🇪🇸',
    unicode: 'U+1F1EA U+1F1F8',
    value: 'Spain',
    title: 'flag for Spain',
    dialCode: '+34'
  },
  {
    id: 'ET',
    emoji: '🇪🇹',
    unicode: 'U+1F1EA U+1F1F9',
    value: 'Ethiopia',
    title: 'flag for Ethiopia',
    dialCode: '+251'
  },
  {
    id: 'EU',
    emoji: '🇪🇺',
    unicode: 'U+1F1EA U+1F1FA',
    value: 'European Union',
    title: 'flag for European Union'
  },
  {
    id: 'FI',
    emoji: '🇫🇮',
    unicode: 'U+1F1EB U+1F1EE',
    value: 'Finland',
    title: 'flag for Finland',
    dialCode: '+358'
  },
  {
    id: 'FJ',
    emoji: '🇫🇯',
    unicode: 'U+1F1EB U+1F1EF',
    value: 'Fiji',
    title: 'flag for Fiji',
    dialCode: '+679'
  },
  {
    id: 'FK',
    emoji: '🇫🇰',
    unicode: 'U+1F1EB U+1F1F0',
    value: 'Falkland Islands (Malvinas)',
    title: 'flag for Falkland Islands (Malvinas)',
    dialCode: '+500'
  },
  {
    id: 'FM',
    emoji: '🇫🇲',
    unicode: 'U+1F1EB U+1F1F2',
    value: 'Micronesia',
    title: 'flag for Micronesia',
    dialCode: '+691'
  },
  {
    id: 'FO',
    emoji: '🇫🇴',
    unicode: 'U+1F1EB U+1F1F4',
    value: 'Faroe Islands',
    title: 'flag for Faroe Islands',
    dialCode: '+298'
  },
  {
    id: 'FR',
    emoji: '🇫🇷',
    unicode: 'U+1F1EB U+1F1F7',
    value: 'France',
    title: 'flag for France',
    dialCode: '+33'
  },
  {
    id: 'GA',
    emoji: '🇬🇦',
    unicode: 'U+1F1EC U+1F1E6',
    value: 'Gabon',
    title: 'flag for Gabon',
    dialCode: '+241'
  },
  {
    id: 'GB',
    emoji: '🇬🇧',
    unicode: 'U+1F1EC U+1F1E7',
    value: 'United Kingdom',
    title: 'flag for United Kingdom',
    dialCode: '+44'
  },
  {
    id: 'GD',
    emoji: '🇬🇩',
    unicode: 'U+1F1EC U+1F1E9',
    value: 'Grenada',
    title: 'flag for Grenada',
    dialCode: '+1 473'
  },
  {
    id: 'GE',
    emoji: '🇬🇪',
    unicode: 'U+1F1EC U+1F1EA',
    value: 'Georgia',
    title: 'flag for Georgia',
    dialCode: '+995'
  },
  {
    id: 'GF',
    emoji: '🇬🇫',
    unicode: 'U+1F1EC U+1F1EB',
    value: 'French Guiana',
    title: 'flag for French Guiana',
    dialCode: '+594'
  },
  {
    id: 'GG',
    emoji: '🇬🇬',
    unicode: 'U+1F1EC U+1F1EC',
    value: 'Guernsey',
    title: 'flag for Guernsey',
    dialCode: '+44'
  },
  {
    id: 'GH',
    emoji: '🇬🇭',
    unicode: 'U+1F1EC U+1F1ED',
    value: 'Ghana',
    title: 'flag for Ghana',
    dialCode: '+233'
  },
  {
    id: 'GI',
    emoji: '🇬🇮',
    unicode: 'U+1F1EC U+1F1EE',
    value: 'Gibraltar',
    title: 'flag for Gibraltar',
    dialCode: '+350'
  },
  {
    id: 'GL',
    emoji: '🇬🇱',
    unicode: 'U+1F1EC U+1F1F1',
    value: 'Greenland',
    title: 'flag for Greenland',
    dialCode: '+299'
  },
  {
    id: 'GM',
    emoji: '🇬🇲',
    unicode: 'U+1F1EC U+1F1F2',
    value: 'Gambia',
    title: 'flag for Gambia',
    dialCode: '+220'
  },
  {
    id: 'GN',
    emoji: '🇬🇳',
    unicode: 'U+1F1EC U+1F1F3',
    value: 'Guinea',
    title: 'flag for Guinea',
    dialCode: '+224'
  },
  {
    id: 'GP',
    emoji: '🇬🇵',
    unicode: 'U+1F1EC U+1F1F5',
    value: 'Guadeloupe',
    title: 'flag for Guadeloupe',
    dialCode: '+590'
  },
  {
    id: 'GQ',
    emoji: '🇬🇶',
    unicode: 'U+1F1EC U+1F1F6',
    value: 'Equatorial Guinea',
    title: 'flag for Equatorial Guinea',
    dialCode: '+240'
  },
  {
    id: 'GR',
    emoji: '🇬🇷',
    unicode: 'U+1F1EC U+1F1F7',
    value: 'Greece',
    title: 'flag for Greece',
    dialCode: '+30'
  },
  {
    id: 'GS',
    emoji: '🇬🇸',
    unicode: 'U+1F1EC U+1F1F8',
    value: 'South Georgia',
    title: 'flag for South Georgia',
    dialCode: '+500'
  },
  {
    id: 'GT',
    emoji: '🇬🇹',
    unicode: 'U+1F1EC U+1F1F9',
    value: 'Guatemala',
    title: 'flag for Guatemala',
    dialCode: '+502'
  },
  {
    id: 'GU',
    emoji: '🇬🇺',
    unicode: 'U+1F1EC U+1F1FA',
    value: 'Guam',
    title: 'flag for Guam',
    dialCode: '+1 671'
  },
  {
    id: 'GW',
    emoji: '🇬🇼',
    unicode: 'U+1F1EC U+1F1FC',
    value: 'Guinea-Bissau',
    title: 'flag for Guinea-Bissau',
    dialCode: '+245'
  },
  {
    id: 'GY',
    emoji: '🇬🇾',
    unicode: 'U+1F1EC U+1F1FE',
    value: 'Guyana',
    title: 'flag for Guyana',
    dialCode: '+595'
  },
  {
    id: 'HK',
    emoji: '🇭🇰',
    unicode: 'U+1F1ED U+1F1F0',
    value: 'Hong Kong',
    title: 'flag for Hong Kong',
    dialCode: '+852'
  },
  {
    id: 'HM',
    emoji: '🇭🇲',
    unicode: 'U+1F1ED U+1F1F2',
    value: 'Heard Island and Mcdonald Islands',
    title: 'flag for Heard Island and Mcdonald Islands'
  },
  {
    id: 'HN',
    emoji: '🇭🇳',
    unicode: 'U+1F1ED U+1F1F3',
    value: 'Honduras',
    title: 'flag for Honduras',
    dialCode: '+504'
  },
  {
    id: 'HR',
    emoji: '🇭🇷',
    unicode: 'U+1F1ED U+1F1F7',
    value: 'Croatia',
    title: 'flag for Croatia',
    dialCode: '+385'
  },
  {
    id: 'HT',
    emoji: '🇭🇹',
    unicode: 'U+1F1ED U+1F1F9',
    value: 'Haiti',
    title: 'flag for Haiti',
    dialCode: '+509'
  },
  {
    id: 'HU',
    emoji: '🇭🇺',
    unicode: 'U+1F1ED U+1F1FA',
    value: 'Hungary',
    title: 'flag for Hungary',
    dialCode: '+36'
  },
  {
    id: 'ID',
    emoji: '🇮🇩',
    unicode: 'U+1F1EE U+1F1E9',
    value: 'Indonesia',
    title: 'flag for Indonesia',
    dialCode: '+62'
  },
  {
    id: 'IE',
    emoji: '🇮🇪',
    unicode: 'U+1F1EE U+1F1EA',
    value: 'Ireland',
    title: 'flag for Ireland',
    dialCode: '+353'
  },
  {
    id: 'IL',
    emoji: '🇮🇱',
    unicode: 'U+1F1EE U+1F1F1',
    value: 'Israel',
    title: 'flag for Israel',
    dialCode: '+972'
  },
  {
    id: 'IM',
    emoji: '🇮🇲',
    unicode: 'U+1F1EE U+1F1F2',
    value: 'Isle of Man',
    title: 'flag for Isle of Man',
    dialCode: '+44'
  },
  {
    id: 'IN',
    emoji: '🇮🇳',
    unicode: 'U+1F1EE U+1F1F3',
    value: 'India',
    title: 'flag for India',
    dialCode: '+91'
  },
  {
    id: 'IO',
    emoji: '🇮🇴',
    unicode: 'U+1F1EE U+1F1F4',
    value: 'British Indian Ocean Territory',
    title: 'flag for British Indian Ocean Territory',
    dialCode: '+246'
  },
  {
    id: 'IQ',
    emoji: '🇮🇶',
    unicode: 'U+1F1EE U+1F1F6',
    value: 'Iraq',
    title: 'flag for Iraq',
    dialCode: '+964'
  },
  {
    id: 'IR',
    emoji: '🇮🇷',
    unicode: 'U+1F1EE U+1F1F7',
    value: 'Iran',
    title: 'flag for Iran',
    dialCode: '+98'
  },
  {
    id: 'IS',
    emoji: '🇮🇸',
    unicode: 'U+1F1EE U+1F1F8',
    value: 'Iceland',
    title: 'flag for Iceland',
    dialCode: '+354'
  },
  {
    id: 'IT',
    emoji: '🇮🇹',
    unicode: 'U+1F1EE U+1F1F9',
    value: 'Italy',
    title: 'flag for Italy',
    dialCode: '+39'
  },
  {
    id: 'JE',
    emoji: '🇯🇪',
    unicode: 'U+1F1EF U+1F1EA',
    value: 'Jersey',
    title: 'flag for Jersey',
    dialCode: '+44'
  },
  {
    id: 'JM',
    emoji: '🇯🇲',
    unicode: 'U+1F1EF U+1F1F2',
    value: 'Jamaica',
    title: 'flag for Jamaica',
    dialCode: '+1 876'
  },
  {
    id: 'JO',
    emoji: '🇯🇴',
    unicode: 'U+1F1EF U+1F1F4',
    value: 'Jordan',
    title: 'flag for Jordan',
    dialCode: '+962'
  },
  {
    id: 'JP',
    emoji: '🇯🇵',
    unicode: 'U+1F1EF U+1F1F5',
    value: 'Japan',
    title: 'flag for Japan',
    dialCode: '+81'
  },
  {
    id: 'KE',
    emoji: '🇰🇪',
    unicode: 'U+1F1F0 U+1F1EA',
    value: 'Kenya',
    title: 'flag for Kenya',
    dialCode: '+254'
  },
  {
    id: 'KG',
    emoji: '🇰🇬',
    unicode: 'U+1F1F0 U+1F1EC',
    value: 'Kyrgyzstan',
    title: 'flag for Kyrgyzstan',
    dialCode: '+996'
  },
  {
    id: 'KH',
    emoji: '🇰🇭',
    unicode: 'U+1F1F0 U+1F1ED',
    value: 'Cambodia',
    title: 'flag for Cambodia',
    dialCode: '+855'
  },
  {
    id: 'KI',
    emoji: '🇰🇮',
    unicode: 'U+1F1F0 U+1F1EE',
    value: 'Kiribati',
    title: 'flag for Kiribati',
    dialCode: '+686'
  },
  {
    id: 'KM',
    emoji: '🇰🇲',
    unicode: 'U+1F1F0 U+1F1F2',
    value: 'Comoros',
    title: 'flag for Comoros',
    dialCode: '+269'
  },
  {
    id: 'KN',
    emoji: '🇰🇳',
    unicode: 'U+1F1F0 U+1F1F3',
    value: 'Saint Kitts and Nevis',
    title: 'flag for Saint Kitts and Nevis',
    dialCode: '+1 869'
  },
  {
    id: 'KP',
    emoji: '🇰🇵',
    unicode: 'U+1F1F0 U+1F1F5',
    value: 'North Korea',
    title: 'flag for North Korea',
    dialCode: '+850'
  },
  {
    id: 'KR',
    emoji: '🇰🇷',
    unicode: 'U+1F1F0 U+1F1F7',
    value: 'South Korea',
    title: 'flag for South Korea',
    dialCode: '+82'
  },
  {
    id: 'KW',
    emoji: '🇰🇼',
    unicode: 'U+1F1F0 U+1F1FC',
    value: 'Kuwait',
    title: 'flag for Kuwait',
    dialCode: '+965'
  },
  {
    id: 'KY',
    emoji: '🇰🇾',
    unicode: 'U+1F1F0 U+1F1FE',
    value: 'Cayman Islands',
    title: 'flag for Cayman Islands',
    dialCode: '+ 345'
  },
  {
    id: 'KZ',
    emoji: '🇰🇿',
    unicode: 'U+1F1F0 U+1F1FF',
    value: 'Kazakhstan',
    title: 'flag for Kazakhstan',
    dialCode: '+7 7'
  },
  {
    id: 'LA',
    emoji: '🇱🇦',
    unicode: 'U+1F1F1 U+1F1E6',
    value: 'Lao People\'s Democratic Republic',
    title: 'flag for Lao People\'s Democratic Republic',
    dialCode: '+856'
  },
  {
    id: 'LB',
    emoji: '🇱🇧',
    unicode: 'U+1F1F1 U+1F1E7',
    value: 'Lebanon',
    title: 'flag for Lebanon',
    dialCode: '+961'
  },
  {
    id: 'LC',
    emoji: '🇱🇨',
    unicode: 'U+1F1F1 U+1F1E8',
    value: 'Saint Lucia',
    title: 'flag for Saint Lucia',
    dialCode: '+1 758'
  },
  {
    id: 'LI',
    emoji: '🇱🇮',
    unicode: 'U+1F1F1 U+1F1EE',
    value: 'Liechtenstein',
    title: 'flag for Liechtenstein',
    dialCode: '+423'
  },
  {
    id: 'LK',
    emoji: '🇱🇰',
    unicode: 'U+1F1F1 U+1F1F0',
    value: 'Sri Lanka',
    title: 'flag for Sri Lanka',
    dialCode: '+94'
  },
  {
    id: 'LR',
    emoji: '🇱🇷',
    unicode: 'U+1F1F1 U+1F1F7',
    value: 'Liberia',
    title: 'flag for Liberia',
    dialCode: '+231'
  },
  {
    id: 'LS',
    emoji: '🇱🇸',
    unicode: 'U+1F1F1 U+1F1F8',
    value: 'Lesotho',
    title: 'flag for Lesotho',
    dialCode: '+266'
  },
  {
    id: 'LT',
    emoji: '🇱🇹',
    unicode: 'U+1F1F1 U+1F1F9',
    value: 'Lithuania',
    title: 'flag for Lithuania',
    dialCode: '+370'
  },
  {
    id: 'LU',
    emoji: '🇱🇺',
    unicode: 'U+1F1F1 U+1F1FA',
    value: 'Luxembourg',
    title: 'flag for Luxembourg',
    dialCode: '+352'
  },
  {
    id: 'LV',
    emoji: '🇱🇻',
    unicode: 'U+1F1F1 U+1F1FB',
    value: 'Latvia',
    title: 'flag for Latvia',
    dialCode: '+371'
  },
  {
    id: 'LY',
    emoji: '🇱🇾',
    unicode: 'U+1F1F1 U+1F1FE',
    value: 'Libya',
    title: 'flag for Libya',
    dialCode: '+218'
  },
  {
    id: 'MA',
    emoji: '🇲🇦',
    unicode: 'U+1F1F2 U+1F1E6',
    value: 'Morocco',
    title: 'flag for Morocco',
    dialCode: '+212'
  },
  {
    id: 'MC',
    emoji: '🇲🇨',
    unicode: 'U+1F1F2 U+1F1E8',
    value: 'Monaco',
    title: 'flag for Monaco',
    dialCode: '+377'
  },
  {
    id: 'MD',
    emoji: '🇲🇩',
    unicode: 'U+1F1F2 U+1F1E9',
    value: 'Moldova',
    title: 'flag for Moldova',
    dialCode: '+373'
  },
  {
    id: 'ME',
    emoji: '🇲🇪',
    unicode: 'U+1F1F2 U+1F1EA',
    value: 'Montenegro',
    title: 'flag for Montenegro',
    dialCode: '+382'
  },
  {
    id: 'MF',
    emoji: '🇲🇫',
    unicode: 'U+1F1F2 U+1F1EB',
    value: 'Saint Martin (French Part)',
    title: 'flag for Saint Martin (French Part)',
    dialCode: '+590'
  },
  {
    id: 'MG',
    emoji: '🇲🇬',
    unicode: 'U+1F1F2 U+1F1EC',
    value: 'Madagascar',
    title: 'flag for Madagascar',
    dialCode: '+261'
  },
  {
    id: 'MH',
    emoji: '🇲🇭',
    unicode: 'U+1F1F2 U+1F1ED',
    value: 'Marshall Islands',
    title: 'flag for Marshall Islands',
    dialCode: '+692'
  },
  {
    id: 'MK',
    emoji: '🇲🇰',
    unicode: 'U+1F1F2 U+1F1F0',
    value: 'Macedonia',
    title: 'flag for Macedonia',
    dialCode: '+389'
  },
  {
    id: 'ML',
    emoji: '🇲🇱',
    unicode: 'U+1F1F2 U+1F1F1',
    value: 'Mali',
    title: 'flag for Mali',
    dialCode: '+223'
  },
  {
    id: 'MM',
    emoji: '🇲🇲',
    unicode: 'U+1F1F2 U+1F1F2',
    value: 'Myanmar',
    title: 'flag for Myanmar',
    dialCode: '+95'
  },
  {
    id: 'MN',
    emoji: '🇲🇳',
    unicode: 'U+1F1F2 U+1F1F3',
    value: 'Mongolia',
    title: 'flag for Mongolia',
    dialCode: '+976'
  },
  {
    id: 'MO',
    emoji: '🇲🇴',
    unicode: 'U+1F1F2 U+1F1F4',
    value: 'Macao',
    title: 'flag for Macao',
    dialCode: '+853'
  },
  {
    id: 'MP',
    emoji: '🇲🇵',
    unicode: 'U+1F1F2 U+1F1F5',
    value: 'Northern Mariana Islands',
    title: 'flag for Northern Mariana Islands',
    dialCode: '+1 670'
  },
  {
    id: 'MQ',
    emoji: '🇲🇶',
    unicode: 'U+1F1F2 U+1F1F6',
    value: 'Martinique',
    title: 'flag for Martinique',
    dialCode: '+596'
  },
  {
    id: 'MR',
    emoji: '🇲🇷',
    unicode: 'U+1F1F2 U+1F1F7',
    value: 'Mauritania',
    title: 'flag for Mauritania',
    dialCode: '+222'
  },
  {
    id: 'MS',
    emoji: '🇲🇸',
    unicode: 'U+1F1F2 U+1F1F8',
    value: 'Montserrat',
    title: 'flag for Montserrat',
    dialCode: '+1664'
  },
  {
    id: 'MT',
    emoji: '🇲🇹',
    unicode: 'U+1F1F2 U+1F1F9',
    value: 'Malta',
    title: 'flag for Malta',
    dialCode: '+356'
  },
  {
    id: 'MU',
    emoji: '🇲🇺',
    unicode: 'U+1F1F2 U+1F1FA',
    value: 'Mauritius',
    title: 'flag for Mauritius',
    dialCode: '+230'
  },
  {
    id: 'MV',
    emoji: '🇲🇻',
    unicode: 'U+1F1F2 U+1F1FB',
    value: 'Maldives',
    title: 'flag for Maldives',
    dialCode: '+960'
  },
  {
    id: 'MW',
    emoji: '🇲🇼',
    unicode: 'U+1F1F2 U+1F1FC',
    value: 'Malawi',
    title: 'flag for Malawi',
    dialCode: '+265'
  },
  {
    id: 'MX',
    emoji: '🇲🇽',
    unicode: 'U+1F1F2 U+1F1FD',
    value: 'Mexico',
    title: 'flag for Mexico',
    dialCode: '+52'
  },
  {
    id: 'MY',
    emoji: '🇲🇾',
    unicode: 'U+1F1F2 U+1F1FE',
    value: 'Malaysia',
    title: 'flag for Malaysia',
    dialCode: '+60'
  },
  {
    id: 'MZ',
    emoji: '🇲🇿',
    unicode: 'U+1F1F2 U+1F1FF',
    value: 'Mozambique',
    title: 'flag for Mozambique',
    dialCode: '+258'
  },
  {
    id: 'NA',
    emoji: '🇳🇦',
    unicode: 'U+1F1F3 U+1F1E6',
    value: 'Namibia',
    title: 'flag for Namibia',
    dialCode: '+264'
  },
  {
    id: 'NC',
    emoji: '🇳🇨',
    unicode: 'U+1F1F3 U+1F1E8',
    value: 'New Caledonia',
    title: 'flag for New Caledonia',
    dialCode: '+687'
  },
  {
    id: 'NE',
    emoji: '🇳🇪',
    unicode: 'U+1F1F3 U+1F1EA',
    value: 'Niger',
    title: 'flag for Niger',
    dialCode: '+227'
  },
  {
    id: 'NF',
    emoji: '🇳🇫',
    unicode: 'U+1F1F3 U+1F1EB',
    value: 'Norfolk Island',
    title: 'flag for Norfolk Island',
    dialCode: '+672'
  },
  {
    id: 'NG',
    emoji: '🇳🇬',
    unicode: 'U+1F1F3 U+1F1EC',
    value: 'Nigeria',
    title: 'flag for Nigeria',
    dialCode: '+234'
  },
  {
    id: 'NI',
    emoji: '🇳🇮',
    unicode: 'U+1F1F3 U+1F1EE',
    value: 'Nicaragua',
    title: 'flag for Nicaragua',
    dialCode: '+505'
  },
  {
    id: 'NL',
    emoji: '🇳🇱',
    unicode: 'U+1F1F3 U+1F1F1',
    value: 'Netherlands',
    title: 'flag for Netherlands',
    dialCode: '+31'
  },
  {
    id: 'NO',
    emoji: '🇳🇴',
    unicode: 'U+1F1F3 U+1F1F4',
    value: 'Norway',
    title: 'flag for Norway',
    dialCode: '+47'
  },
  {
    id: 'NP',
    emoji: '🇳🇵',
    unicode: 'U+1F1F3 U+1F1F5',
    value: 'Nepal',
    title: 'flag for Nepal',
    dialCode: '+977'
  },
  {
    id: 'NR',
    emoji: '🇳🇷',
    unicode: 'U+1F1F3 U+1F1F7',
    value: 'Nauru',
    title: 'flag for Nauru',
    dialCode: '+674'
  },
  {
    id: 'NU',
    emoji: '🇳🇺',
    unicode: 'U+1F1F3 U+1F1FA',
    value: 'Niue',
    title: 'flag for Niue',
    dialCode: '+683'
  },
  {
    id: 'NZ',
    emoji: '🇳🇿',
    unicode: 'U+1F1F3 U+1F1FF',
    value: 'New Zealand',
    title: 'flag for New Zealand',
    dialCode: '+64'
  },
  {
    id: 'OM',
    emoji: '🇴🇲',
    unicode: 'U+1F1F4 U+1F1F2',
    value: 'Oman',
    title: 'flag for Oman',
    dialCode: '+968'
  },
  {
    id: 'PA',
    emoji: '🇵🇦',
    unicode: 'U+1F1F5 U+1F1E6',
    value: 'Panama',
    title: 'flag for Panama',
    dialCode: '+507'
  },
  {
    id: 'PE',
    emoji: '🇵🇪',
    unicode: 'U+1F1F5 U+1F1EA',
    value: 'Peru',
    title: 'flag for Peru',
    dialCode: '+51'
  },
  {
    id: 'PF',
    emoji: '🇵🇫',
    unicode: 'U+1F1F5 U+1F1EB',
    value: 'French Polynesia',
    title: 'flag for French Polynesia',
    dialCode: '+689'
  },
  {
    id: 'PG',
    emoji: '🇵🇬',
    unicode: 'U+1F1F5 U+1F1EC',
    value: 'Papua New Guinea',
    title: 'flag for Papua New Guinea',
    dialCode: '+675'
  },
  {
    id: 'PH',
    emoji: '🇵🇭',
    unicode: 'U+1F1F5 U+1F1ED',
    value: 'Philippines',
    title: 'flag for Philippines',
    dialCode: '+63'
  },
  {
    id: 'PK',
    emoji: '🇵🇰',
    unicode: 'U+1F1F5 U+1F1F0',
    value: 'Pakistan',
    title: 'flag for Pakistan',
    dialCode: '+92'
  },
  {
    id: 'PL',
    emoji: '🇵🇱',
    unicode: 'U+1F1F5 U+1F1F1',
    value: 'Poland',
    title: 'flag for Poland',
    dialCode: '+48'
  },
  {
    id: 'PM',
    emoji: '🇵🇲',
    unicode: 'U+1F1F5 U+1F1F2',
    value: 'Saint Pierre and Miquelon',
    title: 'flag for Saint Pierre and Miquelon',
    dialCode: '+508'
  },
  {
    id: 'PN',
    emoji: '🇵🇳',
    unicode: 'U+1F1F5 U+1F1F3',
    value: 'Pitcairn',
    title: 'flag for Pitcairn',
    dialCode: '+872'
  },
  {
    id: 'PR',
    emoji: '🇵🇷',
    unicode: 'U+1F1F5 U+1F1F7',
    value: 'Puerto Rico',
    title: 'flag for Puerto Rico',
    dialCode: '+1 939'
  },
  {
    id: 'PS',
    emoji: '🇵🇸',
    unicode: 'U+1F1F5 U+1F1F8',
    value: 'Palestinian Territory',
    title: 'flag for Palestinian Territory',
    dialCode: '+970'
  },
  {
    id: 'PT',
    emoji: '🇵🇹',
    unicode: 'U+1F1F5 U+1F1F9',
    value: 'Portugal',
    title: 'flag for Portugal',
    dialCode: '+351'
  },
  {
    id: 'PW',
    emoji: '🇵🇼',
    unicode: 'U+1F1F5 U+1F1FC',
    value: 'Palau',
    title: 'flag for Palau',
    dialCode: '+680'
  },
  {
    id: 'PY',
    emoji: '🇵🇾',
    unicode: 'U+1F1F5 U+1F1FE',
    value: 'Paraguay',
    title: 'flag for Paraguay',
    dialCode: '+595'
  },
  {
    id: 'QA',
    emoji: '🇶🇦',
    unicode: 'U+1F1F6 U+1F1E6',
    value: 'Qatar',
    title: 'flag for Qatar',
    dialCode: '+974'
  },
  {
    id: 'RE',
    emoji: '🇷🇪',
    unicode: 'U+1F1F7 U+1F1EA',
    value: 'Réunion',
    title: 'flag for Réunion',
    dialCode: '+262'
  },
  {
    id: 'RO',
    emoji: '🇷🇴',
    unicode: 'U+1F1F7 U+1F1F4',
    value: 'Romania',
    title: 'flag for Romania',
    dialCode: '+40'
  },
  {
    id: 'RS',
    emoji: '🇷🇸',
    unicode: 'U+1F1F7 U+1F1F8',
    value: 'Serbia',
    title: 'flag for Serbia',
    dialCode: '+381'
  },
  {
    id: 'RU',
    emoji: '🇷🇺',
    unicode: 'U+1F1F7 U+1F1FA',
    value: 'Russia',
    title: 'flag for Russia',
    dialCode: '+7'
  },
  {
    id: 'RW',
    emoji: '🇷🇼',
    unicode: 'U+1F1F7 U+1F1FC',
    value: 'Rwanda',
    title: 'flag for Rwanda',
    dialCode: '+250'
  },
  {
    id: 'SA',
    emoji: '🇸🇦',
    unicode: 'U+1F1F8 U+1F1E6',
    value: 'Saudi Arabia',
    title: 'flag for Saudi Arabia',
    dialCode: '+966'
  },
  {
    id: 'SB',
    emoji: '🇸🇧',
    unicode: 'U+1F1F8 U+1F1E7',
    value: 'Solomon Islands',
    title: 'flag for Solomon Islands',
    dialCode: '+677'
  },
  {
    id: 'SC',
    emoji: '🇸🇨',
    unicode: 'U+1F1F8 U+1F1E8',
    value: 'Seychelles',
    title: 'flag for Seychelles',
    dialCode: '+248'
  },
  {
    id: 'SD',
    emoji: '🇸🇩',
    unicode: 'U+1F1F8 U+1F1E9',
    value: 'Sudan',
    title: 'flag for Sudan',
    dialCode: '+249'
  },
  {
    id: 'SE',
    emoji: '🇸🇪',
    unicode: 'U+1F1F8 U+1F1EA',
    value: 'Sweden',
    title: 'flag for Sweden',
    dialCode: '+46'
  },
  {
    id: 'SG',
    emoji: '🇸🇬',
    unicode: 'U+1F1F8 U+1F1EC',
    value: 'Singapore',
    title: 'flag for Singapore',
    dialCode: '+65'
  },
  {
    id: 'SH',
    emoji: '🇸🇭',
    unicode: 'U+1F1F8 U+1F1ED',
    value: 'Saint Helena, Ascension and Tristan Da Cunha',
    title: 'flag for Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290'
  },
  {
    id: 'SI',
    emoji: '🇸🇮',
    unicode: 'U+1F1F8 U+1F1EE',
    value: 'Slovenia',
    title: 'flag for Slovenia',
    dialCode: '+386'
  },
  {
    id: 'SJ',
    emoji: '🇸🇯',
    unicode: 'U+1F1F8 U+1F1EF',
    value: 'Svalbard and Jan Mayen',
    title: 'flag for Svalbard and Jan Mayen',
    dialCode: '+47'
  },
  {
    id: 'SK',
    emoji: '🇸🇰',
    unicode: 'U+1F1F8 U+1F1F0',
    value: 'Slovakia',
    title: 'flag for Slovakia',
    dialCode: '+421'
  },
  {
    id: 'SL',
    emoji: '🇸🇱',
    unicode: 'U+1F1F8 U+1F1F1',
    value: 'Sierra Leone',
    title: 'flag for Sierra Leone',
    dialCode: '+232'
  },
  {
    id: 'SM',
    emoji: '🇸🇲',
    unicode: 'U+1F1F8 U+1F1F2',
    value: 'San Marino',
    title: 'flag for San Marino',
    dialCode: '+378'
  },
  {
    id: 'SN',
    emoji: '🇸🇳',
    unicode: 'U+1F1F8 U+1F1F3',
    value: 'Senegal',
    title: 'flag for Senegal',
    dialCode: '+221'
  },
  {
    id: 'SO',
    emoji: '🇸🇴',
    unicode: 'U+1F1F8 U+1F1F4',
    value: 'Somalia',
    title: 'flag for Somalia',
    dialCode: '+252'
  },
  {
    id: 'SR',
    emoji: '🇸🇷',
    unicode: 'U+1F1F8 U+1F1F7',
    value: 'Suriname',
    title: 'flag for Suriname',
    dialCode: '+597'
  },
  {
    id: 'SS',
    emoji: '🇸🇸',
    unicode: 'U+1F1F8 U+1F1F8',
    value: 'South Sudan',
    title: 'flag for South Sudan'
  },
  {
    id: 'ST',
    emoji: '🇸🇹',
    unicode: 'U+1F1F8 U+1F1F9',
    value: 'Sao Tome and Principe',
    title: 'flag for Sao Tome and Principe',
    dialCode: '+239'
  },
  {
    id: 'SV',
    emoji: '🇸🇻',
    unicode: 'U+1F1F8 U+1F1FB',
    value: 'El Salvador',
    title: 'flag for El Salvador',
    dialCode: '+503'
  },
  {
    id: 'SX',
    emoji: '🇸🇽',
    unicode: 'U+1F1F8 U+1F1FD',
    value: 'Sint Maarten (Dutch Part)',
    title: 'flag for Sint Maarten (Dutch Part)'
  },
  {
    id: 'SY',
    emoji: '🇸🇾',
    unicode: 'U+1F1F8 U+1F1FE',
    value: 'Syrian Arab Republic',
    title: 'flag for Syrian Arab Republic',
    dialCode: '+963'
  },
  {
    id: 'SZ',
    emoji: '🇸🇿',
    unicode: 'U+1F1F8 U+1F1FF',
    value: 'Swaziland',
    title: 'flag for Swaziland',
    dialCode: '+268'
  },
  {
    id: 'TC',
    emoji: '🇹🇨',
    unicode: 'U+1F1F9 U+1F1E8',
    value: 'Turks and Caicos Islands',
    title: 'flag for Turks and Caicos Islands',
    dialCode: '+1 649'
  },
  {
    id: 'TD',
    emoji: '🇹🇩',
    unicode: 'U+1F1F9 U+1F1E9',
    value: 'Chad',
    title: 'flag for Chad',
    dialCode: '+235'
  },
  {
    id: 'TF',
    emoji: '🇹🇫',
    unicode: 'U+1F1F9 U+1F1EB',
    value: 'French Southern Territories',
    title: 'flag for French Southern Territories'
  },
  {
    id: 'TG',
    emoji: '🇹🇬',
    unicode: 'U+1F1F9 U+1F1EC',
    value: 'Togo',
    title: 'flag for Togo',
    dialCode: '+228'
  },
  {
    id: 'TH',
    emoji: '🇹🇭',
    unicode: 'U+1F1F9 U+1F1ED',
    value: 'Thailand',
    title: 'flag for Thailand',
    dialCode: '+66'
  },
  {
    id: 'TJ',
    emoji: '🇹🇯',
    unicode: 'U+1F1F9 U+1F1EF',
    value: 'Tajikistan',
    title: 'flag for Tajikistan',
    dialCode: '+992'
  },
  {
    id: 'TK',
    emoji: '🇹🇰',
    unicode: 'U+1F1F9 U+1F1F0',
    value: 'Tokelau',
    title: 'flag for Tokelau',
    dialCode: '+690'
  },
  {
    id: 'TL',
    emoji: '🇹🇱',
    unicode: 'U+1F1F9 U+1F1F1',
    value: 'Timor-Leste',
    title: 'flag for Timor-Leste',
    dialCode: '+670'
  },
  {
    id: 'TM',
    emoji: '🇹🇲',
    unicode: 'U+1F1F9 U+1F1F2',
    value: 'Turkmenistan',
    title: 'flag for Turkmenistan',
    dialCode: '+993'
  },
  {
    id: 'TN',
    emoji: '🇹🇳',
    unicode: 'U+1F1F9 U+1F1F3',
    value: 'Tunisia',
    title: 'flag for Tunisia',
    dialCode: '+216'
  },
  {
    id: 'TO',
    emoji: '🇹🇴',
    unicode: 'U+1F1F9 U+1F1F4',
    value: 'Tonga',
    title: 'flag for Tonga',
    dialCode: '+676'
  },
  {
    id: 'TR',
    emoji: '🇹🇷',
    unicode: 'U+1F1F9 U+1F1F7',
    value: 'Turkey',
    title: 'flag for Turkey',
    dialCode: '+90'
  },
  {
    id: 'TT',
    emoji: '🇹🇹',
    unicode: 'U+1F1F9 U+1F1F9',
    value: 'Trinidad and Tobago',
    title: 'flag for Trinidad and Tobago',
    dialCode: '+1 868'
  },
  {
    id: 'TV',
    emoji: '🇹🇻',
    unicode: 'U+1F1F9 U+1F1FB',
    value: 'Tuvalu',
    title: 'flag for Tuvalu',
    dialCode: '+688'
  },
  {
    id: 'TW',
    emoji: '🇹🇼',
    unicode: 'U+1F1F9 U+1F1FC',
    value: 'Taiwan',
    title: 'flag for Taiwan',
    dialCode: '+886'
  },
  {
    id: 'TZ',
    emoji: '🇹🇿',
    unicode: 'U+1F1F9 U+1F1FF',
    value: 'Tanzania',
    title: 'flag for Tanzania',
    dialCode: '+255'
  },
  {
    id: 'TP',
    emoji: '',
    unicode: '',
    value: 'East Timor',
    title: 'flag for East Timor',
    dialCode: '+670'
  },
  {
    id: 'UA',
    emoji: '🇺🇦',
    unicode: 'U+1F1FA U+1F1E6',
    value: 'Ukraine',
    title: 'flag for Ukraine',
    dialCode: '+380'
  },
  {
    id: 'UG',
    emoji: '🇺🇬',
    unicode: 'U+1F1FA U+1F1EC',
    value: 'Uganda',
    title: 'flag for Uganda',
    dialCode: '+256'
  },
  {
    id: 'UM',
    emoji: '🇺🇲',
    unicode: 'U+1F1FA U+1F1F2',
    value: 'United States Minor Outlying Islands',
    title: 'flag for United States Minor Outlying Islands'
  },
  {
    id: 'US',
    emoji: '🇺🇸',
    unicode: 'U+1F1FA U+1F1F8',
    value: 'United States',
    title: 'flag for United States',
    dialCode: '+1'
  },
  {
    id: 'UY',
    emoji: '🇺🇾',
    unicode: 'U+1F1FA U+1F1FE',
    value: 'Uruguay',
    title: 'flag for Uruguay',
    dialCode: '+598'
  },
  {
    id: 'UZ',
    emoji: '🇺🇿',
    unicode: 'U+1F1FA U+1F1FF',
    value: 'Uzbekistan',
    title: 'flag for Uzbekistan',
    dialCode: '+998'
  },
  {
    id: 'VA',
    emoji: '🇻🇦',
    unicode: 'U+1F1FB U+1F1E6',
    value: 'Vatican City',
    title: 'flag for Vatican City',
    dialCode: '+379'
  },
  {
    id: 'VC',
    emoji: '🇻🇨',
    unicode: 'U+1F1FB U+1F1E8',
    value: 'Saint Vincent and The Grenadines',
    title: 'flag for Saint Vincent and The Grenadines',
    dialCode: '+1 784'
  },
  {
    id: 'VE',
    emoji: '🇻🇪',
    unicode: 'U+1F1FB U+1F1EA',
    value: 'Venezuela',
    title: 'flag for Venezuela',
    dialCode: '+58'
  },
  {
    id: 'VG',
    emoji: '🇻🇬',
    unicode: 'U+1F1FB U+1F1EC',
    value: 'Virgin Islands, British',
    title: 'flag for Virgin Islands, British',
    dialCode: '+1 284'
  },
  {
    id: 'VI',
    emoji: '🇻🇮',
    unicode: 'U+1F1FB U+1F1EE',
    value: 'Virgin Islands, U.S.',
    title: 'flag for Virgin Islands, U.S.',
    dialCode: '+1 340'
  },
  {
    id: 'VN',
    emoji: '🇻🇳',
    unicode: 'U+1F1FB U+1F1F3',
    value: 'Viet Nam',
    title: 'flag for Viet Nam',
    dialCode: '+84'
  },
  {
    id: 'VU',
    emoji: '🇻🇺',
    unicode: 'U+1F1FB U+1F1FA',
    value: 'Vanuatu',
    title: 'flag for Vanuatu',
    dialCode: '+678'
  },
  {
    id: 'WF',
    emoji: '🇼🇫',
    unicode: 'U+1F1FC U+1F1EB',
    value: 'Wallis and Futuna',
    title: 'flag for Wallis and Futuna',
    dialCode: '+681'
  },
  {
    id: 'WS',
    emoji: '🇼🇸',
    unicode: 'U+1F1FC U+1F1F8',
    value: 'Samoa',
    title: 'flag for Samoa',
    dialCode: '+685'
  },
  {
    id: 'XK',
    emoji: '🇽🇰',
    unicode: 'U+1F1FD U+1F1F0',
    value: 'Kosovo',
    title: 'flag for Kosovo',
    dialCode: '+383'
  },
  {
    id: 'KV',
    emoji: '🇽🇰',
    unicode: 'U+1F1FD U+1F1F0',
    value: 'Kosovo',
    title: 'flag for Kosovo',
    dialCode: '+383'
  },
  {
    id: 'YE',
    emoji: '🇾🇪',
    unicode: 'U+1F1FE U+1F1EA',
    value: 'Yemen',
    title: 'flag for Yemen',
    dialCode: '+967'
  },
  {
    id: 'YT',
    emoji: '🇾🇹',
    unicode: 'U+1F1FE U+1F1F9',
    value: 'Mayotte',
    title: 'flag for Mayotte',
    dialCode: '+262'
  },
  {
    id: 'ZA',
    emoji: '🇿🇦',
    unicode: 'U+1F1FF U+1F1E6',
    value: 'South Africa',
    title: 'flag for South Africa',
    dialCode: '+27'
  },
  {
    id: 'ZM',
    emoji: '🇿🇲',
    unicode: 'U+1F1FF U+1F1F2',
    value: 'Zambia',
    title: 'flag for Zambia',
    dialCode: '+260'
  },
  {
    id: 'ZW',
    emoji: '🇿🇼',
    unicode: 'U+1F1FF U+1F1FC',
    value: 'Zimbabwe',
    title: 'flag for Zimbabwe',
    dialCode: '+263'
  },
  {
    id: '00',
    value: 'Unknown'
  }
]
export const getCountryObject = Object.fromEntries(country.map((n) => [n.id, n]))

export const getCountry = (code:string) => {
  if (!code) return null
  return getCountryObject?.[code] ? getCountryObject[code].value : `(${code})`
}
