import { ElNotification } from 'element-plus'

/**
 * @description console.log
 * @param {String} name
 * @param {any} data
 * @param {String} type //"" | "success" | "warning" | "info" | "error", unknown, "", boolean
 * @return {Object}
 */
export default (name:string, data:any, type:any = 'info') => {
  if (data.response) {
    // const { message } = data.response.data
    // const { status } = data.response
    // if (status === 500) window.location.replace('/500')
    // const baseUrl = process.env.VUE_APP_BASE_URL
    // const hostName = window.location.origin
    // if (status === 401) {
    //   window.location.replace('/logout')
    // }
    // if ([500].includes(status)) {
    const errors = data?.response?.data?.errors || ''
    const message = data?.response?.data?.message ||
      data?.response?.data?.messages ||
      ''
    ElNotification({
      type,
      message
    })
    if (errors) {
      const arrError = Object.entries(errors)
      arrError.map((e) => {
        ElNotification({
          type,
          message: `${e[0]} - ${e[1]}`
        })
        return true
      })
    }

    // }
  //   return { error: true, message: data.response.data.message }
  // }
  // const message = 'No answer or no existing method'
  // if (data.response && data.response.code !== 500) {
  //   ElNotification({
  //     type,
  //     message
  //   })
  }
  const errors = data?.response?.data?.message || true
  return { errors }

  // const a = store.state.log
  // const b = { method: message.method, time: new Date(), message: message.message, status: message.status }
  // a.push(b)
  // store.commit('SET_STATE', { log: a })
  // console[type]('*',message)
}
