export enum EPlatform {
  PAXUM = 'paxum',
  PAYDEK = 'paydek',
  BANK = 'paxumbank',
  PREPAID = 'paxumbankupiprepaid',
  LOCAL = 'local',
}

export interface IItemProject {
  name: EPlatform,
  label: string,
  smb: string, // именной ID для кейса, технической роли не играет
  urlInfo: string,
  files: string,
  zoho: {
    sar: string,
    eddOng: string,
    eddOnd: string
  }
}

export interface IProject {
  paxum: IItemProject,
  paydek: IItemProject,
  paxumbank: IItemProject,
  paxumbankupiprepaid: IItemProject,
  local: IItemProject
}

const mappers: IProject = {
  paxum: {
    name: EPlatform.PAXUM,
    label: 'Paxum',
    smb: 'PX',
    urlInfo: 'https://customer-info.paxum.com',
    files: 'https://filesss.paxum.com',
    zoho: {
      sar: 'https://forms.zohopublic.eu/paydek/form/PaxumIncInternalSAR1/formperma/0NCHjtwReWnRoMX3ZIi877A4hMNsmfvX4XiAZs66GAw?',
      eddOng: 'https://forms.zohopublic.eu/paydek/form/PaxumIncEnhancedDueDilligenceOnGoingReview/formperma/YZM5bLDdquUDYwXUL2sxnxPS1Q32ealBZYWN72FtZ44?',
      eddOnd: 'https://forms.monitoring.compliancecircle.com/paydek/form/PaxumIncEnhancedDueDilligence2/formperma/znzOJ0FU5jnZyzNG6vSxMzFittLHELpNrdvZF-F49sE?'
    }
  },
  paydek: {
    name: EPlatform.PAYDEK,
    label: 'Paydek',
    smb: 'PD',
    urlInfo: 'https://customer-info.paydek.com',
    files: 'https://filesss.paydek.com',
    zoho: {
      sar: 'https://forms.zohopublic.eu/paydek/form/PaydekInternalSAR/formperma/l2WhMrJzXbHEF2LeaAnM4EpOjPaJHfn5vHpOVocQv8I?',
      eddOng: 'https://forms.zohopublic.eu/paydek/form/PaydekEnhancedDueDiligenceOnGoingReview/formperma/-ja3WKBpITIOp3VhdiDfzcureNQHRyfzax33u3nqSjY?',
      eddOnd: 'https://forms.monitoring.compliancecircle.com/paydek/form/PaydekEnhancedDueDiligenceOnBoarding/formperma/VLE7FWHYgGDoSuioxSpvt-7HfpZy2qWuFBFL9MfFhno?'
    }
  },
  paxumbank: {
    name: EPlatform.BANK,
    label: 'Paxum Bank',
    smb: 'PB',
    urlInfo: 'https://customer-info.paxumbank.com',
    files: 'https://filesss.paxumbank.com',
    zoho: {
      sar: 'https://forms.zohopublic.eu/paydek/form/PaxumBankInternalSAR/formperma/0TrQEBEB5SIot4I0Ang08S0M6gBBUJO7WicTQE2_Y34?',
      eddOng: 'https://forms.zohopublic.eu/paydek/form/PaxumBankEnhancedDueDiligenceOnGoingReview/formperma/uDRzUU4G2NtdJK_EZuZcIrSYp0Vuwt-QCwrsJYcKvtI?',
      eddOnd: 'https://forms.monitoring.compliancecircle.com/paydek/form/PaxumBankEnhancedDueDiligenceOnBoarding/formperma/1QKv6x5TA4PpRRv5LUWT14OaXyKVs7gAb6DijzoRqIU?'
    }
  },
  paxumbankupiprepaid: {
    name: EPlatform.PREPAID,
    label: 'Paxum Bank UPI',
    smb: 'PP',
    urlInfo: 'https://customer-info.paxum.com',
    files: 'https://filesss.paxum.com',
    zoho: {
      sar: '',
      eddOng: '',
      eddOnd: ''
    }
  },
  // dev
  local: {
    name: EPlatform.LOCAL,
    label: 'DEV',
    smb: '*',
    urlInfo: '',
    files: '',
    zoho: {
      sar: '',
      eddOng: '',
      eddOnd: ''
    }
  }
}

class Projects {
  get (name: EPlatform) {
    return mappers?.[name] || mappers?.local
  }

  options () {
    return Object.entries(mappers).map((e:any) => {
      return { value: e[0], label: e[1]?.label }
    })
  }
}

export const Project = new Projects()
