import currency from '../dict/currency'

// добавляет 2 символа после целого числа
const currents = (e:any) => (e % 1 === 0 ? Number(e).toFixed(2) : e)
// разделяет номера по блокам в 3 знака
const toLocalString = (x:any) => {
  x = Number(x)
  x = x.toFixed(2)
  x = !isNaN(x) ? x : 0
  const a = currents(x)
  return String(a).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
}
const toNumberString = (x:any) => {
  x = Number(x)
  x = !isNaN(x) ? x : 0
  x = x.toFixed(0)
  return String(x).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
}

const money = (val = 0, cur = 'USD') => {
  if (typeof val === 'string') {
    val = Number(val)
    if (isNaN(val)) return val + ' ' + cur
  }
  // добавляет значек валюты
  const curr:any = currency
  const currencySymbol = ['USD', 'EUR', 'JPY', 'GBR', 'CHF', 'CNY']
  const CUR = currencySymbol.includes(cur) ? '' : ` ${cur}`
  const symbolMoney = currencySymbol.includes(cur) ? `${curr?.[cur]?.symbol_native} ` : ''
  const currencyCrypto = ['BTC', 'ETH']
  const currencyString = currencyCrypto.includes(cur) ? val : toLocalString(val)
  return `${symbolMoney}${currencyString}${CUR}`
}

export { toNumberString, toLocalString, money }
