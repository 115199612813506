import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sign-component-block" }
const _hoisted_2 = { class: "sign-component" }
const _hoisted_3 = { class: "sign-component-body" }

import CoreButton from '@/shared/button/CoreButton.vue'
import { getCookie } from '@/helpers/cookie'
import LabelValue from '@/shared/labelValue/labelValue.vue'
import { ElInput, ElCard } from 'element-plus'
import { ref, computed } from 'vue'
import { useStore } from '@/pinia/global'

import { useRouter } from 'vue-router'
// name: 'SignComponent',

export default /*@__PURE__*/_defineComponent({
  __name: 'MainPage',
  setup(__props) {

const username = ref('')
const password = ref('')
const router:any = useRouter()
const isValid = computed(() => {
  return !!(username.value.length && password.value.length)
})
const login = () => useStore().login()
const userInfo = () => useStore().userInfo()
const signLogin = () => {
  useStore().loginPass({ username: username.value, password: password.value })
}
const sign = () => {
  const token = getCookie('token') || ''
  if (token) {
    const patch = getCookie('patch')
    if (patch && !patch.indexOf('logout') && patch !== '/') {
      router.push({ path: patch })
    }
    userInfo()
  } else {
    login()
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "sign-component-flex" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "sign-component-header" }, "KYC", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(ElCard), null, {
          default: _withCtx(() => [
            _createVNode(LabelValue, {
              title: "Username",
              class: "mb-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(ElInput), {
                  modelValue: username.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((username).value = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(LabelValue, { title: "Password" }, {
              default: _withCtx(() => [
                _createVNode(_unref(ElInput), {
                  type: "password",
                  modelValue: password.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(CoreButton, {
          disabled: !isValid.value,
          class: "mt-1",
          large: "",
          color: "success",
          onClick: signLogin
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Sign In ")
          ])),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(CoreButton, {
          class: "mt-1",
          large: "",
          color: "primary",
          onClick: sign
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Sign In Azure ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})