import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 1,
  class: "icon"
}

import { defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'CoreButton',
  props: {
    icon: {},
    color: {},
    large: { type: Boolean },
    loader: { type: Boolean },
    disabled: { type: Boolean }
  },
  setup(__props: any) {

const LoaderIcon = defineAsyncComponent(() => import('@/shared/loader/LoaderIcon.vue'))




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled || _ctx.loader,
    class: _normalizeClass(["core-button", [
      _ctx.large && 'core-big',
      !_ctx.color && 'core-btn-grey',
      // color === 'primary-full' && 'core-core-btn-blue-full',
      _ctx.color === 'primary' && 'core-btn-blue',
      _ctx.color === 'success' && 'core-btn-green',
      _ctx.color === 'danger' && 'core-btn-red',
      _ctx.color === 'white' && 'core-btn-white',
      _ctx.color === 'danger-lite' && 'core-btn-red-text',
      _ctx.color === 'warn' && 'core-btn-warn',
      _ctx.disabled && 'core-btn-default',
      _ctx.icon && 'core-btn-icon',
    ]])
  }, [
    (_ctx.icon && _ctx.icon !== 'add')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.icon, "icon"])
        }, null, 2))
      : _createCommentVNode("", true),
    (_ctx.icon === 'add')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "24",
            viewBox: "0 0 24 24"
          }, [
            _createElementVNode("circle", {
              cx: "10",
              cy: "10",
              r: "10",
              transform: "translate(2 2)",
              fill: "#fff"
            }),
            _createElementVNode("path", {
              d: "M12.707,12l3.147-3.146a.5.5,0,0,0-.708-.708L12,11.293,8.854,8.146a.5.5,0,0,0-.708.708L11.293,12,8.146,15.146a.5.5,0,0,0,.708.708L12,12.707l3.146,3.147a.5.5,0,0,0,.708-.708Z",
              transform: "translate(12 -4.971) rotate(45)",
              fill: "currentColor"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.loader)
      ? (_openBlock(), _createBlock(_unref(LoaderIcon), { key: 2 }))
      : _createCommentVNode("", true),
    (!_ctx.loader)
      ? _renderSlot(_ctx.$slots, "default", { key: 3 })
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})