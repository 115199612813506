/**
 * @description Controller response request
 * @param method
 * @param res
 // * @param store
 * @returns {*}
 */

export default (method:string, res:any) => {
  if (process.env.VUE_APP_NODE_ENV === 'development') {
    console.log('*', method)  // eslint-disable-line
  }
  // store.commit('SET_STATE', { [method]: res.data })
  return res.data
}
