import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "label-value_info" }
const _hoisted_2 = { key: 0 }

import { computed } from 'vue'
import InfoTooltip from '@/shared/tooltip/infoTooltip.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'labelValue',
  props: {
    type: {},
    title: {},
    description: {},
    line: { type: Boolean },
    required: { type: Boolean },
    dark: { type: Boolean },
    wrap: { type: Boolean }
  },
  setup(__props: any) {

const props = __props
const bodyClass = computed(() => {
  let text = ''
  if (props.line) text = 'line'
  text = text + ' label-value_body'
  if (props.wrap) {
    text += ' wrap'
  }
  return text
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.dark && 'dark')
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["label-value_span", _ctx.type])
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
      (_ctx.required)
        ? (_openBlock(), _createElementBlock("em", _hoisted_2, "*"))
        : _createCommentVNode("", true),
      (_ctx.description)
        ? (_openBlock(), _createBlock(InfoTooltip, {
            key: 1,
            value: _ctx.description
          }, null, 8, ["value"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(bodyClass.value)
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _cache[0] || (_cache[0] = _createTextVNode("Not Provided"))
      ])
    ], 2)
  ], 2))
}
}

})