<template>
  <div class="sign-component-block">
    <div class="sign-component-flex"/>
    <div class="sign-component">
      <div class="sign-component-header">KYC</div>
      <div class="sign-component-body">
        <el-card>
          <label-value title="Username" class="mb-1">
            <el-input v-model="username" />
          </label-value>
          <label-value title="Password">
            <el-input type="password" v-model="password" />
          </label-value>
        </el-card>
        <core-button
          :disabled="!isValid"
          class="mt-1"
          large
          color="success"
          @click="signLogin">
          Sign In
        </core-button>

        <core-button
          class="mt-1"
          large
          color="primary"
          @click="sign">
          Sign In Azure
        </core-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CoreButton from '@/shared/button/CoreButton.vue'
import { getCookie } from '@/helpers/cookie'
import LabelValue from '@/shared/labelValue/labelValue.vue'
import { ElInput, ElCard } from 'element-plus'
import { ref, computed } from 'vue'
import { useStore } from '@/pinia/global'

import { useRouter } from 'vue-router'
// name: 'SignComponent',
const username = ref('')
const password = ref('')
const router:any = useRouter()
const isValid = computed(() => {
  return !!(username.value.length && password.value.length)
})
const login = () => useStore().login()
const userInfo = () => useStore().userInfo()
const signLogin = () => {
  useStore().loginPass({ username: username.value, password: password.value })
}
const sign = () => {
  const token = getCookie('token') || ''
  if (token) {
    const patch = getCookie('patch')
    if (patch && !patch.indexOf('logout') && patch !== '/') {
      router.push({ path: patch })
    }
    userInfo()
  } else {
    login()
  }
}
</script>

<style lang="scss" scoped>
.sign-component-block {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.sign-component-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  background: url(@/components/Main/assets/image.svg) no-repeat center center;
  background-size: cover;
}

.sign-component {
  display: flex;
  //background-color: #FFFFFF;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-component-header {
  border-radius: 6px 6px 0 0;
  padding: 20px;
  text-align: center;
  font: normal normal bold 27px/39px Arial;
  letter-spacing: 0.49px;
  color: #000000;
  text-transform: uppercase;
  width: 300px;
}

.sign-component-body {
  padding: 0 20px 20px 20px;
  width: 300px;
}

@media (max-width: 800px) {
  .sign-component-flex {
    display: none
  }
  .sign-component {
    background: url(@/components/Main/assets/image.svg) no-repeat center center;
    margin: 0;
    width: 100%;
  }
  .sign-component-header {
    color: #ffffff;
  }
}
</style>
