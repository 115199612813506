export const graphTemplates = (items: any, customerId: number) => {
  const nodes = items?.nodes || []
  const edges = items?.edges || []

  const data = nodes.map((e: any) => {
    const values = edges.filter((k: any) => k.to === e?.id) || []
    const obj = {
      id: e?.id,
      value: e?.customerId,
      name: e?.name,
      itemStyle: {},
      symbolSize: values.length > 3 ? 18 : 8,
    }
    if (e.customerId === Number(customerId)) {
      obj.itemStyle = {
        color: '#5FB822'
      }
      obj.symbolSize = 20
    }
    return obj
  })
  const links = edges.map((e: any) => {
    return {
      source: e?.from,
      target: e?.to,
      type: e?.transferType,
    }
  })
  // const filters = edges.map((e: any) => (e?.transferType))
  // const uniqueChars = [...new Set(filters)]
  // const categories = uniqueChars.map(name => ({ name }))
  return [
    {
      type: 'graph',
      layout: 'force',
      data,
      links,
      // categories,
      roam: true,
      circular: {
        rotateLabel: true,
      },
      colorBy: 'series',
      color: [
        '#5470c6',
        '#91cc75',
        '#fac858',
        '#ee6666',
        '#73c0de',
        '#3ba272',
        '#fc8452',
        '#9a60b4',
        '#ea7ccc',
      ],
      label: {
        show: false,
        position: 'right',
        formatter: '{b}'
      },
      labelLayout: {
        hideOverlap: false
      },
      scaleLimit: {
        min: 1,
        max: 20
      },
      // lineStyle: {
      //   color: 'source',
      //   curveness: 1
      // },
      emphasis: {
        focus: 'adjacency',
        lineStyle: {
          width: 2
        }
      },
      draggable: false,
      // force: {
      //   edgeLength: 5,
      //   repulsion: 70,
      //   gravity: 0.2
      // },
    }
  ]
}
