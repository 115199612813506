<template>
  <div :class="dark && 'dark'">
    <div class="label-value_span" :class="type">
      <span class="label-value_info">{{ title }}</span>
      <em v-if="required">*</em>
      <info-tooltip v-if="description" :value="description"/>
    </div>
    <div :class="bodyClass">
      <slot>Not Provided</slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import InfoTooltip from '@/shared/tooltip/infoTooltip.vue'

const props = defineProps<{
  type?: string
  title?: string
  description?: string
  line?: boolean
  required?: boolean
  dark?: boolean
  wrap?: boolean
}>()
const bodyClass = computed(() => {
  let text = ''
  if (props.line) text = 'line'
  text = text + ' label-value_body'
  if (props.wrap) {
    text += ' wrap'
  }
  return text
})

</script>
<style lang="scss" scoped>
.line {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--info-color);
  width: max-content;
  min-width: 100%;
  min-height: 24px;
  justify-content: center;
}

.label-value_span {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.11px;
  color: #00000080;
  line-height: 1;
  opacity: 1;
  display: flex;
  align-items: center;
  //align-items: flex-end;
  //margin-left: 8px;
}

.label-value_body {
  font-size: 14px;
  font-stretch: normal;
  min-height: 24px;
  font: normal normal normal 14px/20px Arial;
  letter-spacing: 0.25px;
  color: #000000;
  margin-top: 8px;
}
.wrap {
  width: min-content;
  white-space: break-spaces;
}

.label_value__hidden {
  color: rgba(255, 255, 255, 0);
}

.hidden {
  color: rgba(255, 255, 255, 0);
}

.label-value_info {
  margin-right: 3px;
}

.dark :deep(div) {
  color: #FFFFFF !important;
}
.dark :deep(span) {
  color: #FFFFFF !important;
}
.dark :deep(.icon) {
  background-color: #FFFFFF !important;
}
em {
  color: var(--primary-color)
}
</style>
