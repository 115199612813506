import { getCountry } from '@/dict/country'
import { ECustomerType } from '@/dict/enum'
import { IAddress, IClient, IClients, IPhones, IRepresentative } from '@/dict/interface'
import { money } from '@/helpers/prototype'
// import {initLetter} from "@/helpers/string";

export const clientsTemplate = (items: any[] = []) => items.map((item: IClients) => ({
  id: item?.id,
  name: item?.name || '',
  username: item?.username || '',
  birthdate: item?.birthdate || '', // ?
  residenceCountry: getCountry(item?.residenceCountry) || '',
  citizenshipCountry: getCountry(item?.citizenshipCountry) || '',
  taxResidenceCountry: getCountry(item?.taxResidenceCountry) || '',
  businessRegistrationCountry: getCountry(item?.businessRegistrationCountry) || '',
  identityConfirmed: item?.type !== ECustomerType.BUSINESS ? item?.identityConfirmed || false : null,
  riskScore: item?.riskScore || 0,
  status: item?.status || '',
  kycStatus: item?.kycStatus || '',
  type: item?.type || '',
  shareholders: item?.shareholders && item?.shareholders.length ? item?.shareholders : '',
  taxIdentificationNumber: item?.taxIdentificationNumber || '',
  businessTaxNumber: item?.businessTaxNumber || '',
  submittedAt: item?.submittedAt || '',
  verifiedAt: item?.verifiedAt || '',
  createdAt: item?.createdAt || '',
  updatedAt: item?.updatedAt || '',
  project: item?.project || ''
}))
export const clientTemplate = (item: IClient) => {
  const financialInfo = () => {
    const val = item?.financialInfo || {}
    const estimatesList:any[] = val?.estimates || []
    const estimates = estimatesList.map((e):any => {
      e.amount = money(Number(e?.amount), e.currency)
      return e
    })
    return {
      ...val,
      estimates
    }
  }

  return {
    id: item?.id,
    name: item?.name || '',
    username: item?.username || '',
    firstName: item?.firstName || '',
    lastName: item?.lastName || '',
    occupation: item?.occupation || '',
    birthdate: item?.birthdate || '',
    residenceCountry: getCountry(item?.residenceCountry) || '',
    citizenshipCountry: getCountry(item?.citizenshipCountry) || '',
    riskScore: item?.riskScore || 0,
    assignedTo: item?.assignedTo || {},
    status: item?.status || '',
    kycStatus: item?.kycStatus || '',
    type: item?.type || '',
    taxIdentificationNumber: item?.taxIdentificationNumber || '',
    businessTaxNumber: item?.businessTaxNumber || '',
    submittedAt: item?.submittedAt || '',
    verifiedAt: item?.verifiedAt || '',
    createdAt: item?.createdAt || '',
    createdIp: item?.createdIp || '',
    updatedAt: item?.updatedAt || '',
    taxResidenceCountry: getCountry(item?.taxResidenceCountry) || '',
    identityConfirmed: item?.identityConfirmed || '',
    addressConfirmed: item?.addressConfirmed || '',
    additionalQuestions: item?.additionalQuestions || '',
    financialInfo: financialInfo(),
    dueDiligenceQuestionnaire: {
      hasGovernmentPosition: item?.dueDiligenceQuestionnaire?.hasGovernmentPosition || false,
      governmentPositionDetails: item?.dueDiligenceQuestionnaire?.governmentPositionDetails || '',
      hasLawsuitInvolvement: item?.dueDiligenceQuestionnaire?.hasLawsuitInvolvement || false,
      lawsuitInvolvementDetails: item?.dueDiligenceQuestionnaire?.lawsuitInvolvementDetails || '',
      isUsTaxPayer: item?.dueDiligenceQuestionnaire?.isUsTaxPayer || false,
    },
    // business
    awaitingApproval: item.awaitingApproval,
    businessTradingName: item.businessTradingName,
    businessRegistrationCountry: item.businessRegistrationCountry,
    businessRegistrationNumber: item.businessRegistrationNumber,
    businessRegistrationDate: item.businessRegistrationDate,
    businessActivity: item.businessActivity,
    industry: item.industry,
    industryType: item.industryType,
    industrySpecification: item.industrySpecification,
    businessUrl: item.businessUrl,

  }
}
export const clientAddressTemplate = (items: any[] = []) => {
  const result = items.map((item: IAddress) => ({
    id: item?.id,
    customerId: item?.customerId,
    address1: item?.address1 || '',
    address2: item?.customerId || '',
    city: item?.city || '',
    state: item?.state || '',
    country: getCountry(item?.country) || '',
    postalCode: item?.postalCode || '',
    status: item?.status || '',
    type: item?.type || '',
    isPrimary: item?.isPrimary || false,
    isActive: item?.isActive || false,
    isConfirmed: item?.isConfirmed || false,
    createdAt: item?.createdAt || '',
    updatedAt: item?.updatedAt || '',
    documents: item?.documents || [],
  }))
  return result.filter((item: any) => (item.status === 'ACTIVE'))
}
export const clientDocumentTemplate = (items: [] = []) => {
  if (!Array.isArray(items)) return []
  const result = items.map((item: any) => ({
    id: item?.id,
    customerId: item?.customerId || '',
    confirmationType: item?.confirmationType || '',
    name: item?.name || '',
    nameOnDocument: item?.nameOnDocument || '',
    status: item?.status || '',
    // type: initLetter(item?.type || '', '_'),
    type: item?.type || '',
    createdAt: item?.createdAt || '',
    expiresAt: item?.expiresAt || '',
    issuedAt: item?.issuedAt || '',
    updatedAt: item?.updatedAt || '',
    verifiedAt: item?.verifiedAt || '',
  }))
  return result.filter((item: any) => (item.status === 'ACTIVE'))
}
export const clientPhoneTemplate = (items: [] = []) => items.map((item: IPhones) => ({
  id: item?.id,
  customerId: item?.customerId,
  countryPhoneCode: item?.countryPhoneCode || '',
  phone: item?.phone || '',
  extension: item?.extension || '',
  type: item?.type || '',
  status: item?.status || '',
  isActive: item?.isActive || false,
  isConfirmed: item?.isConfirmed || false,
  createdAt: item?.createdAt || '',
  updatedAt: item?.updatedAt || '',
}))
export const clientEmailTemplate = (items: [] = []) => items.map((item:any) => ({
  ...item
}))
export const clientRepresentativeTemplate = (items: IRepresentative[]) => {
  const result:any = {}
  items.map((item:any) => {
    if (item?.registrationCountryCode) {
      item.registrationCountryCode = getCountry(item?.registrationCountryCode) || ''
    }
    if (item?.citizenshipCountry) {
      item.citizenshipCountry = getCountry(item?.citizenshipCountry) || ''
    }
    if (result?.[item.type]) {
      const arr = result[item.type]
      arr.push(item)
      result[item.type] = arr
    } else {
      result[item.type] = [item]
    }
    return true
  })
  return result
}
