<template>
  <div class="layout">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'LayoutPage'
}
</script>

<style lang="scss" scoped>
 .layout {
   padding-bottom: 2rem;
   margin-right: 5%;
   margin-left: 5%;
 }

 @media (min-width: 1440px) {
   .layout {
     margin-right: calc((100% - 1280px) / 2) !important;
     margin-left: calc((100% - 1280px) / 2) !important;
     max-width: 1280px;
   }
 }
 @media (min-width: 1640px) {
   .layout {
     margin-right: calc((100% - 1440px) / 2) !important;
     margin-left: calc((100% - 1440px) / 2) !important;
     max-width: 1440px;
   }
 }
 @media (min-width: 1840px) {
   .layout {
     margin-right: calc((100% - 1640px) / 2) !important;
     margin-left: calc((100% - 1640px) / 2) !important;
     max-width: 1640px;
   }
 }
</style>
